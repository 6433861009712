import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AddData,
  DeleteData,
  GetData,
  UpdateData,
} from "../../api/GeneralFile";
import { FaSort } from "react-icons/fa";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import loadingGIF from "../../assets/images/loading-gif.gif";
import EditUserData from "./EditUserData";
import DownloadIcon from "@mui/icons-material/Download";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import Papa from "papaparse";

import * as XLSX from "xlsx";
import Upload from "@mui/icons-material/Upload";

const XLSXDownload = (props) => {
  const handleDownload = () => {
    const wb = XLSX.utils.book_new();

    const getTotalMarks = (user) => {
      if (props.category === "Intra School") {
        const offline = user?.OfflineIntraMarks ? user?.OfflineIntraMarks : 0;
        const online = user?.IntraSchoolMarks ? user?.IntraSchoolMarks : 0;
        const total = parseFloat(offline) + parseFloat(online);
        return total.toFixed(0);
      } else if (props.category === "State") {
        const offline = user?.OfflineStateMarks ? user?.OfflineStateMarks : 0;
        const online = user?.StateMarks ? user?.StateMarks : 0;
        const total = parseFloat(offline) + parseFloat(online);
        return total.toFixed(0);
      } else if (props.category === "National") {
        const offline = user?.OfflineNationalMarks
          ? user?.OfflineNationalMarks
          : 0;
        const online = user?.NationalMarks ? user?.NationalMarks : 0;
        const total = parseFloat(offline) + parseFloat(online);
        return total.toFixed(0);
      }
    };

    const sheetData = props
      ? props.data.map((entry) => {
          return props.category === "Intra School"
            ? {
                Name: entry.name,
                Dob: entry.dob,
                Class: entry.grade,
                PhoneNo: entry.phoneNo,
                Hallticket: entry.hallticket,
                IntraSchoolMarks: entry.IntraSchoolMarks
                  ? entry.IntraSchoolMarks
                  : 0,
                OfflineIntraMarks: entry.OfflineIntraMarks
                  ? entry.OfflineIntraMarks
                  : 0,
                TotalMarks: getTotalMarks(entry),
                IntraSchoolTime: entry.IntraSchoolTime
                  ? entry.IntraSchoolTime
                  : 0,
                SchoolName: props.school.schoolName,
              }
            : props.category === "State"
              ? {
                  Name: entry.name,
                  DOB: entry.dob,
                  Class: entry.grade,
                  PhoneNo: entry.phoneNo,
                  Hallticket: entry.hallticket,
                  StateMarks: entry.StateMarks ? entry.StateMarks : 0,
                  OfflineStateMarks: entry.OfflineStateMarks
                    ? entry.OfflineStateMarks
                    : 0,
                  TotalMarks: getTotalMarks(entry),
                  StateTime: entry.StateTime ? entry.StateTime : 0,
                  SchoolName: props.school.schoolName,
                }
              : {
                  Name: entry.name,
                  DOB: entry.dob,
                  Class: entry.grade,
                  PhoneNo: entry.phoneNo,
                  Hallticket: entry.hallticket,
                  NationalMarks: entry.NationalMarks ? entry.NationalMarks : 0,
                  OfflineNationalMarks: entry.OfflineNationalMarks
                    ? entry.OfflineNationalMarks
                    : 0,
                  TotalMarks: getTotalMarks(entry),
                  NationalTime: entry.NationalTime ? entry.NationalTime : 0,
                  SchoolName: props.school.schoolName,
                };
        })
      : null;

    const ws = XLSX.utils.json_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(wb, ws, props.name);
    XLSX.writeFile(wb, `${props.school.schoolName}-${props.name}` + ".xlsx");
  };
  XLSXDownload.propTypes = {
    data: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    school: PropTypes.object.isRequired,
    category: PropTypes.string.isRequired,
  };
  return (
    <Button variant="contained" size="small" onClick={handleDownload}>
      Export file as excel sheet
    </Button>
  );
};

const XLSXDownload2 = (props) => {
  const handleDownload = () => {
    const wb = XLSX.utils.book_new();

    const getTotalMarks = (user) => {
      if (props.category === "Intra School") {
        const offline = user?.OfflineIntraMarks ? user?.OfflineIntraMarks : 0;
        const online = user?.IntraSchoolMarks ? user?.IntraSchoolMarks : 0;
        const total = parseFloat(offline) + parseFloat(online);
        return total.toFixed(0);
      } else if (props.category === "State") {
        const offline = user?.OfflineStateMarks ? user?.OfflineStateMarks : 0;
        const online = user?.StateMarks ? user?.StateMarks : 0;
        const total = parseFloat(offline) + parseFloat(online);
        return total.toFixed(0);
      } else if (props.category === "National") {
        const offline = user?.OfflineNationalMarks
          ? user?.OfflineNationalMarks
          : 0;
        const online = user?.NationalMarks ? user?.NationalMarks : 0;
        const total = parseFloat(offline) + parseFloat(online);
        return total.toFixed(0);
      }
    };

    const sheetData = props
      ? props.data
          .filter((entry) => props.rankList(entry) === "-")
          .map((entry) => {
            return props.category === "Intra School"
              ? {
                  Name: entry.name,
                  Dob: entry.dob,
                  Class: entry.grade,
                  PhoneNo: entry.phoneNo,
                  Hallticket: entry.hallticket,
                  IntraSchoolMarks: entry.IntraSchoolMarks
                    ? entry.IntraSchoolMarks
                    : 0,
                  OfflineIntraMarks: entry.OfflineIntraMarks
                    ? entry.OfflineIntraMarks
                    : 0,
                  TotalMarks: getTotalMarks(entry),
                  IntraSchoolTime: entry.IntraSchoolTime
                    ? entry.IntraSchoolTime
                    : 0,
                  SchoolName: props.school.schoolName,
                }
              : props.category === "State"
                ? {
                    Name: entry.name,
                    DOB: entry.dob,
                    Class: entry.grade,
                    PhoneNo: entry.phoneNo,
                    Hallticket: entry.hallticket,
                    StateMarks: entry.StateMarks ? entry.StateMarks : 0,
                    OfflineStateMarks: entry.OfflineStateMarks
                      ? entry.OfflineStateMarks
                      : 0,
                    TotalMarks: getTotalMarks(entry),
                    StateTime: entry.StateTime ? entry.StateTime : 0,
                    SchoolName: props.school.schoolName,
                  }
                : {
                    Name: entry.name,
                    DOB: entry.dob,
                    Class: entry.grade,
                    PhoneNo: entry.phoneNo,
                    Hallticket: entry.hallticket,
                    NationalMarks: entry.NationalMarks
                      ? entry.NationalMarks
                      : 0,
                    OfflineNationalMarks: entry.OfflineNationalMarks
                      ? entry.OfflineNationalMarks
                      : 0,
                    TotalMarks: getTotalMarks(entry),
                    NationalTime: entry.NationalTime ? entry.NationalTime : 0,
                    SchoolName: props.school.schoolName,
                  };
          })
      : null;

    const ws = XLSX.utils.json_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(wb, ws, props.name);
    XLSX.writeFile(wb, `${props.school.schoolName}_${getTitle2()}` + ".xlsx");
  };
  XLSXDownload2.propTypes = {
    data: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    school: PropTypes.object.isRequired,
    category: PropTypes.string.isRequired,
  };
  const getTitle2 = () => {
    if (props.category === "Intra School") {
      return "Not Selected for State";
    } else if (props.category === "State") {
      return "Not Selected for National";
    } else if (props.category === "National") {
      return "Not in top rank List";
    }
  };
  const getTitle = () => {
    if (props.category === "Intra School") {
      return "Download Students Not Selected for State";
    } else if (props.category === "State") {
      return "Download Students Not Selected for National";
    } else if (props.category === "National") {
      return "Download Students Not in top rank List";
    }
  };
  return (
    <Button variant="contained" size="small" onClick={handleDownload}>
      {getTitle()}
    </Button>
  );
};

const ViewUserList = (props) => {
  const { t } = useTranslation();
  console.log(props);
  const [searchInput, setSearchInput] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsLimit, setRowsLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchBy, setSearchBy] = useState("Name");
  const [users, setUsers] = useState([]);
  const [category, setCategory] = useState("Intra School");
  const [displayUpdate, setDisplayUpdate] = useState(false);
  const [userToBeUpdated, setUserToBeUpdated] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [distinctGrades, setDistinctGrades] = useState([]);
  const [grade, setGrade] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const [sortedAllUsers, setSortedAllUsers] = useState([]);

  const searchData = () => {
    setIsLoading(true);

    GetData(
      `/gfoExamSchoolLogin/getschool/students?searchBy=${searchBy}&search=${searchInput}&pageNumber=${pageNumber}&rowsLimit=${rowsLimit}&category=${category}&schoolid=${props?.school?._id}&grade=${grade}&isChecked=${isChecked}`,
    )
      .then((response) => {
        console.log(response, "response");
        setUsers(response.data.results.paginatedUsers);
        setTotalPage(response.data.results.totalPage);
        setAllUsers(response.data.filteredStudents);
        setDistinctGrades(response.data.distinctGrades);

        if (response.data?.message === false) {
          toast(response.data?.message);
        }
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    searchData();
  }, [pageNumber, rowsLimit, props, category, grade, isChecked]); //searchInput

  useEffect(() => {
    // setSearchInput("");
    setPageNumber(1);
  }, [grade, category, isChecked]);

  // console.log(searchInput);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setPageNumber(1);
      event.preventDefault();
      setSearchInput(event.target.value);
      searchData();
    }
  };

  const [sortOrder, setSortOrder] = useState("asc");

  const sortByColumn = (columnName) => {
    const sortComparator = (key, order) => (a, b) => {
      let comparison = 0;
      if (columnName === "Total Marks") {
        const totalMarksA = getTotalMarks(a);
        const totalMarksB = getTotalMarks(b);
        if (parseInt(totalMarksA) !== parseInt(totalMarksB)) {
          comparison = parseInt(totalMarksA) > parseInt(totalMarksB) ? 1 : -1;
        } else {
          const specificMarksA = getMarks(a);
          const specificMarksB = getMarks(b);

          if (parseInt(specificMarksA) !== parseInt(specificMarksB)) {
            comparison =
              parseInt(specificMarksA) > parseInt(specificMarksB) ? 1 : -1;
          } else {
            const timeA = getTime(a);
            const timeB = getTime(b);

            if (parseInt(timeA) !== parseInt(timeB)) {
              comparison = parseInt(timeA) < parseInt(timeB) ? 1 : -1;
            } else {
              const offlineA = getOfflineMarks(a);
              const offlineB = getOfflineMarks(b);
              comparison = parseInt(offlineA) > parseInt(offlineB) ? 1 : -1;
            }
          }
        }
      } else {
        comparison = a[key] > b[key] ? 1 : -1;
      }
      return order === "asc" ? comparison : -comparison;
    };

    if (
      columnName === "_id" ||
      columnName === "Name" ||
      columnName === "Total Marks"
    ) {
      const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
      const sortedUsers = allUsers
        .slice()
        .sort(sortComparator(columnName, newSortOrder));
      setSortOrder(newSortOrder);
      setUsers(sortedUsers);
      setAllUsers(sortedUsers);
    }
  };

  useEffect(() => {
    const sortComparator = (key, order) => (a, b) => {
      let comparison = 0;

      const totalMarksA = getTotalMarks(a);
      const totalMarksB = getTotalMarks(b);
      if (parseInt(totalMarksA) !== parseInt(totalMarksB)) {
        comparison = parseInt(totalMarksA) > parseInt(totalMarksB) ? 1 : -1;
      } else {
        const specificMarksA = getMarks(a);
        const specificMarksB = getMarks(b);

        if (parseInt(specificMarksA) !== parseInt(specificMarksB)) {
          comparison =
            parseInt(specificMarksA) > parseInt(specificMarksB) ? 1 : -1;
        } else {
          const timeA = getTime(a);
          const timeB = getTime(b);

          if (parseInt(timeA) !== parseInt(timeB)) {
            comparison = parseInt(timeA) < parseInt(timeB) ? 1 : -1;
          } else {
            const offlineA = getOfflineMarks(a);
            const offlineB = getOfflineMarks(b);
            comparison = parseInt(offlineA) > parseInt(offlineB) ? 1 : -1;
          }
        }
      }

      return order === "asc" ? comparison : -comparison;
    };

    const newSortOrder = "desc";
    const sortedUsers = allUsers.slice().sort(sortComparator(newSortOrder));

    setSortedAllUsers(sortedUsers);
  }, [allUsers]);

  const generateHallTicketNo = () => {
    AddData(
      `/gfoExamSchoolLogin/generate-hall-tickets?schoolid=${props?.school?._id}`,
    )
      .then((response) => {
        toast(response.data?.message);
        searchData();
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const statusHandler = (status, id) => {
    AddData(`/gfoExamSchoolLogin/setStateStatus`, {
      schoolid: props?.school?._id,
      id,
      status,
    })
      .then((response) => {
        toast(response.data?.message);
        searchData();
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const statusHandler2 = (status, id) => {
    AddData(`/gfoExamSchoolLogin/setNationalStatus`, {
      schoolid: props?.school?._id,
      id,
      status,
    })
      .then((response) => {
        toast(response.data?.message);
        searchData();
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const statusHandler3 = (status, id) => {
    AddData(`/gfoExamSchoolLogin/setFinalStatus`, {
      schoolid: props?.school?._id,
      id,
      status,
    })
      .then((response) => {
        toast(response.data?.message);
        searchData();
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getMarks = (user) => {
    if (category === "Intra School") {
      return user?.IntraSchoolMarks || user?.IntraSchoolMarks === 0
        ? user?.IntraSchoolMarks
        : 0;
    } else if (category === "State") {
      return user?.StateMarks || user?.StateMarks === 0 ? user?.StateMarks : 0;
    } else if (category === "National") {
      return user?.NationalMarks || user?.NationalMarks === 0
        ? user?.NationalMarks
        : 0;
    }
  };

  const getTime = (user) => {
    if (category === "Intra School") {
      return user?.IntraSchoolTime ? user?.IntraSchoolTime : 0;
    } else if (category === "State") {
      return user?.StateTime ? user?.StateTime : 0;
    } else if (category === "National") {
      return user?.NationalTime ? user?.NationalTime : 0;
    }
  };

  const valueSetForData = (row) => {
    if (category === "Intra School") {
      return (
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Switch
                checked={row?.isState}
                onChange={(event) => {
                  const newStatus = event.target.checked;
                  if (rankList(row) === "-" && !row?.isState) {
                    Swal.fire({
                      title:
                        "Student not in top 3 or cutoff percentage,Do you want to continue?",
                      showDenyButton: true,
                      confirmButtonText: "Yes",
                      denyButtonText: `No`,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        statusHandler(newStatus, row._id);
                      }
                    });
                  } else {
                    statusHandler(newStatus, row._id);
                  }
                }}
                name="statusToggle"
                color="primary"
              />
            }
            label={row?.isState ? "Qualified" : "Not Qualified"}
          />
        </FormControl>
      );
    } else if (category === "State") {
      return (
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Switch
                checked={row?.isNational}
                onChange={(event) => {
                  const newStatus = event.target.checked;
                  if (rankList(row) === "-" && !row?.isNational) {
                    Swal.fire({
                      title:
                        "Student not in top 3 or cutoff percentage,Do you want to continue?",
                      showDenyButton: true,
                      confirmButtonText: "Yes",
                      denyButtonText: `No`,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        statusHandler2(newStatus, row._id);
                      }
                    });
                  } else {
                    statusHandler2(newStatus, row._id);
                  }
                }}
                name="statusToggle"
                color="primary"
              />
            }
            label={row?.isNational ? "Qualified" : "Not Qualified"}
          />
        </FormControl>
      );
    } else if (category === "National") {
      return (
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Switch
                checked={row?.isFinalQualified}
                onChange={(event) => {
                  const newStatus = event.target.checked;
                  if (rankList(row) === "-" && !row?.isFinalQualified) {
                    Swal.fire({
                      title:
                        "Student not in top 3 or cutoff percentage,Do you want to continue?",
                      showDenyButton: true,
                      confirmButtonText: "Yes",
                      denyButtonText: `No`,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        statusHandler3(newStatus, row._id);
                      }
                    });
                  } else {
                    statusHandler3(newStatus, row._id);
                  }
                }}
                name="statusToggle"
                color="primary"
              />
            }
            label={row?.isFinalQualified ? "Qualified" : "Not Qualified"}
          />
        </FormControl>
      );
    }
  };
  const getOfflineMarks = (user) => {
    if (category === "Intra School") {
      return user?.OfflineIntraMarks ? user?.OfflineIntraMarks : 0;
    } else if (category === "State") {
      return user?.OfflineStateMarks ? user?.OfflineStateMarks : 0;
    } else if (category === "National") {
      return user?.OfflineNationalMarks ? user?.OfflineNationalMarks : 0;
    }
  };

  const getTotalMarks = (user) => {
    if (category === "Intra School") {
      const offline = user?.OfflineIntraMarks ? user?.OfflineIntraMarks : 0;
      const online = user?.IntraSchoolMarks ? user?.IntraSchoolMarks : 0;
      const total = parseFloat(offline) + parseFloat(online);
      return total.toFixed(0);
    } else if (category === "State") {
      const offline = user?.OfflineStateMarks ? user?.OfflineStateMarks : 0;
      const online = user?.StateMarks ? user?.StateMarks : 0;
      const total = parseFloat(offline) + parseFloat(online);
      return total.toFixed(0);
    } else if (category === "National") {
      const offline = user?.OfflineNationalMarks
        ? user?.OfflineNationalMarks
        : 0;
      const online = user?.NationalMarks ? user?.NationalMarks : 0;
      const total = parseFloat(offline) + parseFloat(online);
      return total.toFixed(0);
    }
  };
  const deleteHandler = (id, schoolid) => {
    Swal.fire({
      title: "Confirm removal of this Student Data?",
      showDenyButton: true,
      confirmButtonText: "Yes remove it",
      denyButtonText: `No don't remove it`,
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteData(`/gfoExamSchoolLogin/delete-student/${id}/${schoolid}`)
          .then((response) => {
            toast(response.data.message);
            searchData();
          })
          .catch((error) => {
            console.log(error);
            console.error(error.response.data.message);
          });
      }
    });
  };
  const repeatExam = (status, category, id) => {
    Swal.fire({
      title: "Are You sure You want to change it?",
      text: `If Yes, Then also change the exam date and time for ${category} exam.`,
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        AddData(`/gfoExamSchoolLogin/setRepeatExamStatus`, {
          schoolid: props?.school?._id,
          id,
          status,
          category,
        })
          .then((response) => {
            toast(response.data?.message);
            searchData();
          })
          .catch((err) => {
            console.error(err, "errpr");
            toast(err.data?.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    });
  };
  const repeatExamForData = (row) => {
    if (category === "Intra School") {
      return (
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Switch
                checked={row?.isRepeatIntraSchool}
                onChange={(event) => {
                  const newStatus = event.target.checked;
                  repeatExam(newStatus, category, row._id);
                }}
                name="statusToggle"
                color="primary"
              />
            }
            label={row?.isRepeatIntraSchool ? "Repeat Exam" : "Not Repeat Exam"}
          />
        </FormControl>
      );
    } else if (category === "State") {
      return (
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Switch
                checked={row?.isRepeatState}
                onChange={(event) => {
                  const newStatus = event.target.checked;
                  repeatExam(newStatus, category, row._id);
                }}
                name="statusToggle"
                color="primary"
              />
            }
            label={row?.isRepeatState ? "Repeat Exam" : "Not Repeat Exam"}
          />
        </FormControl>
      );
    } else if (category === "National") {
      return (
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Switch
                checked={row?.isRepeatNational}
                onChange={(event) => {
                  const newStatus = event.target.checked;
                  repeatExam(newStatus, category, row._id);
                }}
                name="statusToggle"
                color="primary"
              />
            }
            label={row?.isRepeatNational ? "Repeat Exam" : "Not Repeat Exam"}
          />
        </FormControl>
      );
    }
  };

  const [intraSchoolPercentage, setIntraSchoolPercentage] = useState(0);
  const [statePercentage, setStatePercentage] = useState(0);
  const [nationalPercentage, setNationalPercentage] = useState(0);

  const getPerData = () => {
    setIsLoading(true);
    GetData(`/admin/getQualified/percentagerange`)
      .then((response) => {
        console.log(response);

        setIntraSchoolPercentage(
          parseInt(response.data.percentagerange[0].intraSchoolPercentage)
            ? parseInt(response.data.percentagerange[0].intraSchoolPercentage)
            : 0,
        );
        setStatePercentage(
          parseInt(response.data.percentagerange[0].statePercentage)
            ? parseInt(response.data.percentagerange[0].statePercentage)
            : 0,
        );
        setNationalPercentage(
          parseInt(response.data.percentagerange[0].nationalPercentage)
            ? parseInt(response.data.percentagerange[0].nationalPercentage)
            : 0,
        );
      })
      .catch((err) => {
        console.error(err, "error");
        toast(err.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPerData();
  }, []);

  const rankList = (user) => {
    if (
      sortedAllUsers?.length > 0 &&
      user?.hallticket &&
      intraSchoolPercentage &&
      statePercentage &&
      nationalPercentage
      // &&
      // ((category === "Intra School" &&
      //   user?.IntraSchoolTime &&
      //   parseInt(user?.IntraSchoolTime) > 0) ||
      //   (category === "State" &&
      //     user?.StateTime &&
      //     parseInt(user?.StateTime) > 0) ||
      //   (category === "National" &&
      //     user?.NationalTime &&
      //     parseInt(user?.NationalTime) > 0))
    ) {
      if (
        user?.hallticket?.toString() ===
        sortedAllUsers[0]?.hallticket?.toString()
      ) {
        return "1st";
      } else if (
        user?.hallticket?.toString() ===
        sortedAllUsers[1]?.hallticket?.toString()
      ) {
        return "2nd";
      } else if (
        user?.hallticket?.toString() ===
        sortedAllUsers[2]?.hallticket?.toString()
      ) {
        return "3rd";
      } else if (
        intraSchoolPercentage &&
        statePercentage &&
        nationalPercentage
      ) {
        if (
          category === "Intra School" &&
          (getTotalMarks(user) / 200) * 100 >= parseInt(intraSchoolPercentage)
        ) {
          return `>${intraSchoolPercentage}%`;
        } else if (
          category === "State" &&
          (getTotalMarks(user) / 200) * 100 >= parseInt(statePercentage)
        ) {
          return `>${statePercentage}%`;
        } else if (
          category === "National" &&
          (getTotalMarks(user) / 200) * 100 >= parseInt(nationalPercentage)
        ) {
          return `>${nationalPercentage}%`;
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };

  const handleStudentChanged = (e) => {
    const rankArray = sortedAllUsers
      .filter((user) => rankList(user) !== "-")
      .map((user) => {
        return {
          _id: user._id, // Store the user's _id
          rank: rankList(user), // Store the rank or percentage result from rankList
        };
      });
    console.log(rankArray);
    rankArray.forEach((user) => {
      const status = e.target.checked; // Get the new status (true/false) from the switch
      const userId = user._id; // Get the user ID from rankArray

      // Call the appropriate status handler based on the category
      if (category === "Intra School") {
        statusHandler(status, userId);
      } else if (category === "State") {
        statusHandler2(status, userId);
      } else if (category === "National") {
        statusHandler3(status, userId);
      }
    });
  };

  const handleCheckedStatus = () => {
    const rankArray = sortedAllUsers
      .filter((user) => rankList(user) !== "-")
      .map((user) => {
        return {
          _id: user._id, // Store the user's _id
          rank: rankList(user), // Store the rank or percentage result from rankList
        };
      });
    if (rankArray.length > 0) {
      if (category === "Intra School") {
        return sortedAllUsers
          .filter((user) => rankList(user) !== "-")
          .every((user) => user.isState);
      } else if (category === "State") {
        return sortedAllUsers
          .filter((user) => rankList(user) !== "-")
          .every((user) => user.isNational);
      } else if (category === "National") {
        return sortedAllUsers
          .filter((user) => rankList(user) !== "-")
          .every((user) => user.isFinalQualified);
      }
    } else {
      return false;
    }
  };

  const tableRow = (user, index) => {
    return (
      <TableRow
        style={{
          backgroundColor:
            sortedAllUsers?.length > 0 && rankList(user) != "-"
              ? "lightgreen"
              : "",
        }}
        key={user._id}
      >
        <TableCell>{user.name}</TableCell>

        <TableCell>
          {user.dob && user?.dob !== "NaN-NaN-NaN" ? user.dob : "-"}
        </TableCell>
        <TableCell>{user.grade ? user.grade : "-"}</TableCell>
        <TableCell>{user.phoneNo ? user.phoneNo : "-"}</TableCell>
        <TableCell>{user.hallticket ? user.hallticket : "-"}</TableCell>

        <TableCell>{getOfflineMarks(user)}</TableCell>

        <TableCell>{getMarks(user)}</TableCell>
        <TableCell>{getTotalMarks(user)}</TableCell>
        <TableCell>{getTime(user)}</TableCell>
        <TableCell>
          {(sortedAllUsers?.length > 0 && rankList(user)) || "-"}
        </TableCell>
        {grade && category === "Intra School" && (
          <TableCell>{valueSetForData(user)}</TableCell>
        )}
        {grade && category === "Intra School" && (
          <TableCell>{repeatExamForData(user)}</TableCell>
        )}
        <TableCell>
          <Button
            size="small"
            variant="contained"
            sx={{ marginRight: "5px" }}
            onClick={(event) => {
              setUserToBeUpdated((userToBeUpdated) => user);
              setDisplayUpdate(true);
            }}
          >
            {" "}
            <EditIcon />
          </Button>
          {/* <Button
            size="small"
            variant="contained"
            sx={{ marginRight: "5px" }}
            onClick={(event) => {
              deleteHandler(user._id, props?.school?._id);
            }}
          >
            {" "}
            <DeleteIcon />{" "}
          </Button> */}
        </TableCell>
      </TableRow>
    );
  };

  const renderItem = (row, index) => {
    return tableRow(row, index);
  };

  const getLabel = () => {
    if (category === "Intra School") {
      return "Is State Qualified?";
    } else if (category === "State") {
      return "Is National Qualified?";
    } else if (category === "National") {
      return "Is Qualified?";
    }
  };

  const handleFormat = () => {
    const wb = XLSX.utils.book_new();
    const sheetData = allUsers.map((entry) => {
      return category === "Intra School"
        ? {
            Name: entry.name,
            Class: entry.grade,
            PhoneNo: entry.phoneNo,
            Hallticket: entry.hallticket,

            OfflineIntraMarks: entry.OfflineIntraMarks
              ? entry.OfflineIntraMarks
              : 0,
          }
        : category === "State"
          ? {
              Name: entry.name,
              Class: entry.grade,
              PhoneNo: entry.phoneNo,
              Hallticket: entry.hallticket,

              OfflineStateMarks: entry.OfflineStateMarks
                ? entry.OfflineStateMarks
                : 0,
            }
          : {
              Name: entry.name,

              Class: entry.grade,
              PhoneNo: entry.phoneNo,
              Hallticket: entry.hallticket,
              OfflineNationalMarks: entry.OfflineNationalMarks
                ? entry.OfflineNationalMarks
                : 0,
            };
    });

    const ws = XLSX.utils.json_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(wb, ws, "OflineMarks Format");
    XLSX.writeFile(wb, "OflineMarks Format" + ".xlsx");
  };

  const fileInputRef = useRef(null);
  const [filename, setFilename] = useState("");

  const uploadScore = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const submitScore = (data) => {
    console.log(data);
    UpdateData("/gfoExamSchoolLogin/addOfflineScore", {
      data,
      schoolid: props.school._id,
      category,
    })
      .then((response) => {
        console.log(response, "response add user");
        toast(response.data.message);
        fileInputRef.current.value = null;
        setFilename("");
        searchData();
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    setFilename(file.name);
    if (fileExtension === "csv") {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const newData = results.data.map((row) => {
            const newRow = {};

            Object.keys(row).forEach((key) => {
              const [fieldName] = key.toLowerCase().split("(");
              const fieldValue = row[key];

              newRow[fieldName] = fieldValue;
            });
            return newRow;
          });

          submitScore(newData);
        },
      });
    } else if (fileExtension === "xlsx") {
      const reader = new FileReader();
      reader.onload = (e) => {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const headers = jsonData[0];
        const rows = jsonData.slice(1);

        const formattedData = rows
          .map((row) => {
            let rowData = {};
            headers.forEach((header, index) => {
              if (row[index]) {
                // rowData[header.toLowerCase()] = row[index];
                const [fieldName] = header.toLowerCase().split("(");
                const fieldValue = row[index];

                rowData[fieldName] = fieldValue;
              }
            });

            return rowData;
          })
          .filter((row) => Object.keys(row).length !== 0);

        submitScore(formattedData);
      };
      reader.readAsBinaryString(file);
    } else {
      toast.error(
        "Please ensure that uploaded files are in either .csv or .xlsx format.",
      );
    }
  };

  // Function to handle checkbox state change
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <div style={{ marginTop: "15px" }}>
      {displayUpdate && (
        <EditUserData
          user={userToBeUpdated}
          displayUpdate={displayUpdate}
          setDisplayUpdate={setDisplayUpdate}
          schoolid={props?.school?._id}
          searchData={searchData}
          category={category}
        />
      )}

      {!displayUpdate && (
        <div style={{ textAlign: "center" }}>
          {isLoading ? (
            <img
              src={loadingGIF}
              alt="loading"
              style={{
                width: "50px",
                height: "50px",
                margin: "0 auto",
                marginTop: "50px",
              }}
            />
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={generateHallTicketNo}
                    // disabled={users[0]?.hallticket}
                    disabled={users?.some((user) => user.hallticket)}
                  >
                    Generate Hall Ticket No.
                  </Button>
                </div>
                <div
                  style={{ display: "flex", gap: "30px", alignItems: "center" }}
                >
                  <XLSXDownload
                    data={allUsers}
                    name={`${category}_Students`}
                    category={category}
                    school={props?.school}
                  />
                  <XLSXDownload2
                    data={allUsers}
                    name={`${category}_Students`}
                    category={category}
                    school={props?.school}
                    rankList={rankList}
                  />
                </div>

                <div style={{ display: "flex", gap: "10px" }}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Category{" "}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="category"
                      name="category"
                      value={category}
                      onChange={(event) => {
                        setCategory(event.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="Intra School"
                        control={<Radio />}
                        label="Intra School"
                      />
                      <FormControlLabel
                        value="State"
                        control={<Radio />}
                        label="State"
                      />
                      <FormControlLabel
                        value="National"
                        control={<Radio />}
                        label="National"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl>
                    <InputLabel id="select-label">{t("Searchby")}</InputLabel>
                    <Select
                      labelId="select-label"
                      id="select"
                      label="Search by"
                      size="small"
                      value={searchBy}
                      onChange={(event) => setSearchBy(event.target.value)}
                    >
                      <MenuItem value="Name">{t("Name")}</MenuItem>
                      <MenuItem value="hallticket">{t("Hall Ticket")}</MenuItem>
                    </Select>
                  </FormControl>
                  <form noValidate>
                    <TextField
                      size="small"
                      label={t("Searchhere")}
                      value={searchInput}
                      autoComplete="off"
                      onChange={(event) => setSearchInput(event.target.value)}
                      onKeyDown={handleKeyPress}
                    />
                  </form>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setPageNumber(1);
                      searchData();
                    }}
                  >
                    {t("Filter")}
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setSearchInput("");
                      searchData();
                    }}
                  >
                    {t("Clear")}
                  </Button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                  gap: 10,
                }}
              >
                <Button variant="contained" size="small" onClick={handleFormat}>
                  Formate For Offline Score <DownloadIcon />
                </Button>
                <Button variant="contained" size="small" onClick={uploadScore}>
                  <input
                    type="file"
                    accept=".csv, .xlsx"
                    ref={fileInputRef}
                    hidden
                    onChange={handleFileChange}
                  />
                  {filename ? filename : " Upload Offline Score"} <Upload />
                </Button>
                <div style={{ borderWidth: 3, padding: 5 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        color="primary"
                      />
                    }
                    label="Display Offline/Online Mismatch Data"
                  />
                </div>
                {grade && category === "Intra School" && (
                  <div style={{ borderWidth: 3, padding: 5 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={handleCheckedStatus()}
                          // onChange={(event) => {
                          //   const newStatus = event.target.checked;
                          //   statusHandler(newStatus, row._id);
                          // }}
                          onChange={handleStudentChanged}
                          name="statusToggle"
                          color="primary"
                        />
                      }
                      label={
                        handleCheckedStatus()
                          ? "Students Qualified"
                          : "Students Not Qualified"
                      }
                    />
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                {" "}
                <FormControl>
                  <FormLabel>Class </FormLabel>
                  <RadioGroup
                    row
                    aria-label="grade"
                    name="grade"
                    value={grade}
                    onChange={(event) => {
                      setGrade(event.target.value);
                    }}
                  >
                    {distinctGrades?.length > 0 &&
                      distinctGrades?.map((std, index) => (
                        <FormControlLabel
                          value={std}
                          control={<Radio />}
                          label={std}
                          key={String(index + 1)}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              </div>

              <TableContainer
                component={Paper}
                sx={{ marginTop: "5px", height: 440 }}
              >
                <Table size="small">
                  <TableHead sx={{ backgroundColor: "#333", color: "#fff" }}>
                    <TableRow>
                      <TableCell sx={{ color: "#fff" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {t("Name")}{" "}
                          <FaSort
                            style={{ padding: "5px", backgroundColor: "#000" }}
                            onClick={(event) => sortByColumn(t("Name"))}
                          />
                        </div>
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>{t("DOB")}</TableCell>
                      <TableCell sx={{ color: "#fff" }}>Grade</TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {t("Phone No")}
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        Hall Ticket No.
                      </TableCell>

                      <TableCell sx={{ color: "#fff" }}>
                        Offline Marks
                      </TableCell>

                      <TableCell sx={{ color: "#fff" }}>Online Marks</TableCell>

                      <TableCell sx={{ color: "#fff" }}>
                        Total Marks
                        <FaSort
                          style={{ padding: 2, backgroundColor: "#000" }}
                          onClick={(event) => sortByColumn("Total Marks")}
                        />
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        Online Exam Time(sec)
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>Rank</TableCell>
                      {grade && category === "Intra School" && (
                        <TableCell sx={{ color: "#fff" }}>
                          {getLabel()}
                        </TableCell>
                      )}
                      {grade && category === "Intra School" && (
                        <TableCell sx={{ color: "#fff" }}>
                          Repeat Exam Option
                        </TableCell>
                      )}
                      <TableCell sx={{ color: "#fff" }}>
                        {t("Actions")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users?.map((user, index) => renderItem(user, index))}
                  </TableBody>
                </Table>
              </TableContainer>

              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "30px",
                }}
              >
                <div
                  style={{ marginTop: "15px", display: "flex", gap: "30px" }}
                >
                  <Button
                    variant="contained"
                    disabled={isLoading}
                    onClick={() => props.setDisplayUserList(false)}
                  >
                    Back
                  </Button>
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <Typography>{t("RPP")}</Typography>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        {t("Rows")}
                      </InputLabel>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={rowsLimit}
                        label="Rows limit"
                        onChange={(event) => {
                          setRowsLimit(event.target.value);
                          setPageNumber(1);
                        }}
                      >
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="15">15</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: "0px",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <SkipPreviousIcon
                      onClick={(event) =>
                        setPageNumber((pageNumber) =>
                          pageNumber <= 1 ? 1 : --pageNumber,
                        )
                      }
                    />
                    <div style={{ minWidth: "50px", textAlign: "center" }}>
                      {pageNumber} of {totalPage}
                    </div>
                    <SkipNextIcon
                      onClick={(event) =>
                        setPageNumber((pageNumber) =>
                          pageNumber < totalPage ? ++pageNumber : totalPage,
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ViewUserList;
