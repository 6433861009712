import React, { useEffect, useState } from "react";
import { GetData } from "../../api/GeneralFile";
import { toast } from "react-toastify";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import loadingGIF from "../../assets/images/loading-gif.gif";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import GSTInvoiceFormate from "./GSTInvoiceFormate";
import ReactDOM from "react-dom";
import DownloadIcon from "@mui/icons-material/Download";

const ModalPaperPdfPlan = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsLimit, setRowsLimit] = useState(10);
  const [category, setCategory] = useState("");
  const [grade, setGrade] = useState("Select Grade...");
  const [level, setLevel] = useState("Select Level...");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState([]);
  const [allSubscriptionList, setAllSubscriptionList] = useState([]);

  const { t } = useTranslation();

  const getAllSubscriptionList = () => {
    GetData(`/subscription-settings`)
      .then((response) => {
        console.log(response, "response");

        setAllSubscriptionList(
          response.data.results.paginatedSubscriptionSettings,
        );
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);
      });
  };

  useEffect(() => {
    getAllSubscriptionList();
  }, []);

  const searchData = () => {
    setIsLoading(true);
    GetData(
      `/finance-details/subscriptions/ModelPaper/details?grade=${grade}&level=${level}&pageNumber=${pageNumber}&rowsLimit=${rowsLimit}&startDate=${startDate}&endDate=${endDate}`,
    )
      .then((response) => {
        console.log(response, "response");
        setTotalData(response.data.results.paginatedData);
        setTotalPage(response.data.results.totalPage);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);

        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    searchData();
  }, [pageNumber, rowsLimit, grade, level]);

  function convertTimestampToDate(timestamp) {
    const date = new Date(timestamp); // Convert timestamp to a Date object
    const day = String(date.getDate()).padStart(2, "0"); // Get day and add leading zero if necessary
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1 and pad with zero
    const year = date.getFullYear(); // Get the year

    return `${day}-${month}-${year}`; // Return the formatted date
  }
  const menuItems = [
    { key: "1", value: "Select Grade...", text: "Select Grade..." },
    { key: "2", value: "1st", text: "1st" },
    { key: "3", value: "2nd", text: "2nd" },
    { key: "4", value: "3rd", text: "3rd" },
    { key: "5", value: "4th", text: "4th" },
    { key: "6", value: "5th", text: "5th" },
    { key: "7", value: "6th", text: "6th" },
    { key: "8", value: "7th", text: "7th" },
    { key: "9", value: "8th", text: "8th" },
    { key: "10", value: "9th", text: "9th" },
    { key: "11", value: "10th", text: "10th" },
    { key: "12", value: "11th", text: "11th" },
    { key: "13", value: "12th", text: "12th" },
  ];

  const levelItem = [
    { key: "1", value: "Select Level...", text: "Select Level..." },
    { key: "2", value: "Intra School", text: "Intra School" },
    { key: "3", value: "State", text: "State" },
    { key: "4", value: "National", text: "National" },
  ];

  const [isDownloading, setIsDownloading] = useState(false);
  const handlePrintAll = async () => {
    setIsDownloading(true);
    const pdf = new jsPDF("p", "mm", "a4"); // Create a new PDF document
    let isFirstPage = true;

    for (let i = 0; i < totalData.length; i++) {
      const element = document.createElement("div");
      document.body.appendChild(element);
      ReactDOM.render(
        <GSTInvoiceFormate
          invoiceData={totalData[i]}
          text="ModalPaper"
          index={i + 1}
        />,
        element,
      );
      const canvas = await html2canvas(element, { scale: 2 });

      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210; // width of A4 page in mm
      const pageHeight = 295; // height of A4 page in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      if (isFirstPage) {
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        isFirstPage = false;
      } else {
        // For subsequent invoices, add a new page
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      }
      document.body.removeChild(element);
    }

    pdf.save(`Download.pdf`);
    setIsDownloading(false);
  };

  const handlePrintSingle = async (data, index) => {
    const pdf = new jsPDF("p", "mm", "a4"); // Create a new PDF document

    const element = document.createElement("div");
    document.body.appendChild(element);
    ReactDOM.render(
      <GSTInvoiceFormate
        invoiceData={data}
        text="ModalPaper"
        index={index + 1}
      />,
      element,
    );
    const canvas = await html2canvas(element, { scale: 2 });

    const imgData = canvas.toDataURL("image/png");
    const imgWidth = 210; // width of A4 page in mm
    const pageHeight = 295; // height of A4 page in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

    document.body.removeChild(element);

    pdf.save(`Invoice.pdf`);
  };

  return (
    <>
      <div
        style={{
          padding: "15px",
          marginTop: "15px",
          backgroundColor: "#fff",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <div style={{ flexDirection: "row", gap: "15px", display: "flex" }}>
          <FormControl>
            <InputLabel id="language-select-label">Grade</InputLabel>
            <Select
              labelId="grade-select-label"
              label="Grade"
              value={grade}
              required
              onChange={(event) => {
                setGrade(event.target.value);
              }}
            >
              {menuItems.map((item) => (
                <MenuItem key={item.key} value={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="language-select-label">Select Level</InputLabel>
            <Select
              labelId="grade-select-label"
              label="Select Level"
              value={level}
              required
              onChange={(event) => {
                setLevel(event.target.value);
              }}
            >
              {levelItem.map((item) => (
                <MenuItem key={item.key} value={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Button
          variant="contained"
          onClick={handlePrintAll}
          disabled={isDownloading}
        >
          {isDownloading ? "Downloading..." : "Download All Invoice as PDF"}
        </Button>
        <div>
          <FormControl>
            <TextField
              type="date"
              value={startDate}
              onChange={(event) => {
                setStartDate(event.target.value);
              }}
            />
          </FormControl>

          <FormControl>
            <TextField
              type="date"
              value={endDate}
              onChange={(event) => {
                setEndDate(event.target.value);
              }}
            />
          </FormControl>
          <Button
            variant="contained"
            onClick={searchData}
            style={{ margin: 10 }}
          >
            Filter
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setStartDate("");
              setEndDate("");
            }}
            style={{ marginRight: 10 }}
          >
            Cancel
          </Button>
        </div>
      </div>

      <div>
        {isLoading ? (
          <img
            src={loadingGIF}
            alt=""
            style={{ width: "50px", height: "50px" }}
          />
        ) : (
          <>
            {" "}
            <div style={{ marginTop: "3px" }}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="collapsible table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#333", color: "#fff" }}>
                      <TableCell sx={{ color: "#fff" }}>{t("Name")}</TableCell>
                      <TableCell sx={{ color: "#fff" }}>Phone No.</TableCell>
                      <TableCell sx={{ color: "#fff" }}>Plan Name</TableCell>
                      <TableCell sx={{ color: "#fff" }}>Grade</TableCell>
                      <TableCell sx={{ color: "#fff" }}>Leve</TableCell>
                      <TableCell sx={{ color: "#fff" }}>Payment Id</TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        Original Plan Price
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>CGST</TableCell>
                      <TableCell sx={{ color: "#fff" }}>SGST</TableCell>
                      <TableCell sx={{ color: "#fff" }}>Total Tax</TableCell>
                      <TableCell sx={{ color: "#fff" }}>Total Price</TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        Purchased Plan Date
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>Invoice</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {totalData?.map((item, index) => (
                      <TableRow key={String(index + 1)}>
                        <TableCell>{item.user?.Name}</TableCell>
                        <TableCell>{item.user?.Phone || "-"}</TableCell>
                        <TableCell>
                          {item.subscriptionHistory?.subscription?.name || "-"}
                        </TableCell>
                        <TableCell>{item.subscriptionHistory?.grade}</TableCell>
                        <TableCell>{item.subscriptionHistory?.level}</TableCell>
                        <TableCell>
                          {item.subscriptionHistory?.paymentId || "-"}
                        </TableCell>
                        <TableCell>
                          {item.subscriptionHistory?.subscription?.price || "-"}
                        </TableCell>
                        <TableCell>9%</TableCell>
                        <TableCell>9%</TableCell>
                        <TableCell>
                          {`${(
                            (parseInt(
                              item.subscriptionHistory.subscription.price,
                            ) *
                              9) /
                            100
                          ).toFixed(2)}+${(
                            (parseInt(
                              item.subscriptionHistory.subscription.price,
                            ) *
                              9) /
                            100
                          ).toFixed(2)}`}
                        </TableCell>
                        <TableCell>
                          {parseInt(
                            item.subscriptionHistory.subscription?.price,
                          ) +
                            (parseInt(
                              item.subscriptionHistory.subscription.price,
                            ) *
                              18) /
                              100 || "-"}
                        </TableCell>
                        <TableCell>
                          {convertTimestampToDate(
                            item.subscriptionHistory.date,
                          ) || "-"}
                        </TableCell>
                        <TableCell>
                          <Button
                            size="small"
                            variant="contained"
                            sx={{ marginRight: "5px" }}
                            onClick={() => handlePrintSingle(item, index)}
                          >
                            <DownloadIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div
              style={{
                marginTop: "15px",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <FormControl>
                <InputLabel id="demo-simple-select-label">Rows</InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={rowsLimit}
                  label="Rows limit"
                  onChange={(event) => {
                    setRowsLimit(event.target.value);
                    setPageNumber(1);
                  }}
                >
                  <MenuItem value="5">5</MenuItem>
                  <MenuItem value="10">10</MenuItem>
                  <MenuItem value="15">15</MenuItem>
                </Select>
              </FormControl>
              <div
                style={{
                  display: "flex",
                  gap: "0px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SkipPreviousIcon
                  onClick={(event) =>
                    setPageNumber((pageNumber) =>
                      pageNumber <= 1 ? 1 : --pageNumber,
                    )
                  }
                />
                <div style={{ minWidth: "50px", textAlign: "center" }}>
                  {pageNumber} of {totalPage}
                </div>
                <SkipNextIcon
                  onClick={(event) =>
                    setPageNumber((pageNumber) =>
                      pageNumber < totalPage ? ++pageNumber : totalPage,
                    )
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ModalPaperPdfPlan;
